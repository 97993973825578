import { makeRecord } from "sdi/source";
import { fromRecord } from "sdi/locale";

export function attribution() {
  return `Bassin EFE Liège`;
}

export function credits() {
  return fromRecord(
    makeRecord(
      "Fond de plan: CartoWeb ®©",
      "Achtergrond: CartoWeb ®©"
    )
  );
}
